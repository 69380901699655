import React from "react";
import './Navbar.css'
import {Link } from 'react-router-dom'
import { ImExit } from "react-icons/im";

import logo from "../../img/Logo_KIT.svg.png" 




export default function navbar (){


    let domain = window.location.hostname;
    let api = '/api'

    let url_prod = 'https://' + domain + api
  
    let url_dev = 'http://' + domain +':5000' 


      const handleLogOut = (event) => {


            /*window.location.href = 'https://login-dev.helmholtz.de/home/' */
      
            localStorage.clear();
            window.location.href = "/";
      
           }


return(


<div className="navbar">
      <div className="navbar-logo"> <img src={logo} alt="MyApp Logo" className="logo-image" /></div>
      <ul className="navbar-menu">
        <li className="navbar-item">
          <a href="https://www.bildung-forschung.digital/digitalezukunft/de/wissen/Datenkompetenzen/datenkompetenzen_wissenschaftlichen_nachwuchs/Projekte/daphona.html?nn=729750" target="_blank" rel="noopener noreferrer">About</a>
        </li>
        <li className="navbar-item">
          <a href={`${url_prod}/PrivacyPolicy/`} target="_blank" rel="noopener noreferrer">Privacy policy</a>
        </li>
        <li className="navbar-item">
          <a href="/contact" target="_blank" rel="noopener noreferrer">Contact</a>
        </li>
        <li className="navbar-item">
          <h5>{'Hello ' + localStorage.getItem('user_id') + ' !'}</h5>
          <ImExit className="icon-logout" onClick={handleLogOut} />
        </li>
      </ul>
    </div>




 
      
  

      


)

}