import React, { useEffect , useContexte} from "react";
import './LogIn.css'
import axiosInstance from '../../Axios.js'
import {Link } from 'react-router-dom'





export default function LogIn() {


    let domain = window.location.hostname;
    let api = '/api'

    let url_prod = 'https://' + domain + api
  
    let url_dev = 'http://' + domain +':5000' 


  
  


     const handleLogInGitHub = (event) => {

      console.log('github')
      axiosInstance.post('/caosdb_login_github/',
                       {
                  headers:{  
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": "true",
                    "Content-Type": "application/json",}, 
                      
                }
              ).then((response)  => {
                console.log(response.data['info'])
                window.location.href = response.data['info']
              })
              
              .catch((error) => {console.log(error)
                                
            
            })
                 
            
      

      /*window.location.href = 'https://login-dev.helmholtz.de/home/' */

     // localStorage.setItem(
      //  "currentUser", 'caosdb'
        
      //);
      //window.location.href = "/Home";

     }


     const handleLogInGoogle = (event) => {


      axiosInstance.post('/caosdb_login_google/',
                       {
                  headers:{  
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": "true",
                    "Content-Type": "application/json",}, 
                      
                }
              ).then((response)  => {
                //window.open("https://login-dev.helmholtz.de/home/")
                console.log(response.data['info'])
                window.location.href = response.data['info']
              })
              
              .catch((error) => {console.log(error)
                                
            
            })
                 
            
      

      /*window.location.href = 'https://login-dev.helmholtz.de/home/' */

     // localStorage.setItem(
      //  "currentUser", 'caosdb'
        
      //);
      //window.location.href = "/Home";

     }

     const handleLogInOpenConnect = (event) => {


      axiosInstance.post('/caosdb_login_openconnect/',
                       {
                  headers:{  
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": "true",
                    "Content-Type": "application/json",}, 
                      
                }
              ).then((response)  => {
                //window.open("https://login-dev.helmholtz.de/home/")
                console.log(response.data['info'])
                window.location.href = response.data['info']
              })
              
              .catch((error) => {console.log(error)
                                
            
            })
                 
            
      

      /*window.location.href = 'https://login-dev.helmholtz.de/home/' */

     // localStorage.setItem(
      //  "currentUser", 'caosdb'
        
      //);
      //window.location.href = "/Home";

     }


     
     


    return(
            <div className="login">
              <h4>Sign in using your institutional account (or Github, Google, ORCID)</h4>
              
                
              
               {/*<input
                  type="button"
                  value="LOGIN"
                  className="btn"
                  onClick={handleLogIn}
      
    />*/} 
             <input
                  type="button"
                  value="Institutional Account"
                  className="btn"
                  onClick={handleLogInOpenConnect}
                  disabled
      
                />
                <input
                  type="button"
                  value="GitHub Account"
                  className="btn"
                  onClick={handleLogInGitHub}
      
                />


<nav style={{ marginTop: '30%' }}>
        <Link to="https://www.bildung-forschung.digital/digitalezukunft/de/wissen/Datenkompetenzen/datenkompetenzen_wissenschaftlichen_nachwuchs/Projekte/daphona.html?nn=729750" style={{ marginRight: '10px' }} target="_blank" rel="noopener noreferrer">About</Link>
        <Link to={`${url_prod}/PrivacyPolicy/`}  style={{ marginRight: '10px' }} target="_blank" rel="noopener noreferrer">Privacy policy</Link>
        <Link to="/contact" style={{ marginRight: '10px' }}>Contact</Link>
      </nav>
            </div>
          
    )




}
