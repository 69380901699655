
import React, { useState } from 'react';

import './Sidebar.css'



export default function MaterialElemts ({handleMaterial, ListMaterial} ) {

  const [Materials, setMaterials] = useState('')



  const handleSelectChange = (data) => {
    const value = data;
    if (value === 'clear') {
      setMaterials(''); // Reset to the default placeholder value
      handleMaterial('')
    } else {
      setMaterials(value);
      handleMaterial(value)
    }
  };


  
  



    


    return (

 

    <div>
 <h2 className='Title-Geometry'> Material</h2>

<hr className="separator" />


{/*

<input
        list="ListMaterial"
        value={Materials}
        onChange={e => {setMaterials(e.target.value); handleMaterial(e.target.value)}}
        placeholder="Choose or type material name"
        className="input-menu"
      />

        <datalist id="ListMaterial">
        {ListMaterial.map((option, index) => (
          <option key={index} value={option} />
        ))}
      </datalist>
      */}




      <div >
      <select id="dropdown" value={Materials}  onChange={e => handleSelectChange(e.target.value)} className="input-menu">
        <option value="" disabled>Select Material</option>
        {Materials && <option value="clear">Clear selection</option>}
        {ListMaterial.length > 0 ? (
          ListMaterial.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))
        ) : (
        <option disabled>No options available</option>
        )}

      

      </select>
    </div>
     
      </div>
    
      
    

      




    )

    


};