import React from 'react';
import './Header.css'

import Sidebar from './SideBar.js'

import TmatrixItems from './Tmatrix-Item.js'
import TmatrixEmbedding from './Tmatrix-Embedding.js'


import TmatrixMode from './Tmatrix-Mode.js'
import TmatrixScatter from './Tmatrix-scatter.js'

const TmatrixContent = ({ data, onClose }) => {

  
  //const items = ['T-Matrix', 'Embedding', 'Geometry', 'Material', 'Computation', 'Modes' ]; 

  const rows = []
  rows.push(data)
  console.log(rows)

  const [items, setItems] = React.useState([
    { id: 1, name: 'General Information',  content: 'General_Information', selected: false },
    { id: 2, name: 'Scattering Cross-Section',  content: 'Frequency', selected: false },
    { id: 3, name: 'Scattering Matrix Elements', content: 'T-Matrix', selected: false },
    {id: 4, name: 'TMatrix Magnitude Heatmaps and Surface Plots', content: 'Scattered_TMatrix' , selected: false}
   
    
  ]);

  const [selectedContent, setSelectedContent] = React.useState('General_Information');

  const handleItemSelected = (selectedItem) => {
    const newItems = items.map(item =>
      ({ ...item, selected: item.id === selectedItem.id })
    );
    setItems(newItems);
    setSelectedContent(selectedItem.content);
  };


    
   

    const renderContent = () => {
      switch (selectedContent) {
        case 'T-Matrix':
          return <TmatrixMode data = {data}/>;
        case 'General_Information':
          return <TmatrixEmbedding data = {data}/>;
        case 'Scattered_TMatrix':
          return <TmatrixScatter data = {data}/>;
        case 'Frequency':
          return <TmatrixItems data = {data}/>;
        default:
          return null;
      }
    };

  return (
    <div className="tmatrix-modal-backdrop" onClick={onClose}>
      <div className="tmatrix-modal-content" onClick={e => e.stopPropagation()}>

      <Sidebar items={items} onItemSelected={handleItemSelected} />

      <div className="table-container">
      {renderContent()}

          </div>

    <div style={{ flex: 1, padding: '10px' }}><button onClick={onClose} className='button-close'>Close</button></div>
    
      </div>
      
    </div>
  );
};

export default TmatrixContent;




