import React, { useEffect, useState } from 'react';

import './Sidebar.css'


import GeometryShape from './GeometryShape.js'

import MetricsElemts from './Metrics-Elemts.js'

import MaterialElemts from './Material-element.js'



export default function Sidebar({handleGeometryShape, handleMaterial, ListMaterial, handleMetrics}) {

    

    return(

        

<section className="SideBar">

    <div>


    <GeometryShape sendGeometryShape ={handleGeometryShape}/>

    <MetricsElemts  handleMetrics = {handleMetrics} />

    <MaterialElemts  handleMaterial = {handleMaterial} ListMaterial = {ListMaterial}/>

    </div>

</section>
    


        
    )




}