import React, { useState, useEffect } from "react";
import './MainPage.css'
import Header from '../Header/Header.js'

import Sidebar from '../Sidebar/Sidebar.js'
import Navbar from "../Navbar/Navbar.js"

import axiosInstance from '../../Axios.js'
import UploadingBox from '../Header/UploadingBox.js'








export default function MainPage() {



    const [ReceivedGeometryShape, setReceivedGeometryShape] = useState('');
    const [ReceivedMaterial, setReceivedMaterial] = useState('');
    const [GlobalMatrix, setGlobalMatrix] = useState([])
    const [NumPage, setNumPage] = useState(1)
    const [Total, setTotal] = useState(0)
    const [ListMaterial, setListMaterial] = useState([])

    const [ResultMatrix, setResult_Matrix] = useState([])

    const [ValueMetrics, setValueMetrics] = useState({})
    const [isUploading, setIsUploading] = useState(false);
    const [WaitingMessage, setWaitingMessage] = useState('Searching please wait....');

  const handleGeometryShape = (data) => {
    setReceivedGeometryShape(data);


  };

  const handleMaterial = (data) => {
    


        console.log(data)

       
        if(data === ''){

            setReceivedMaterial('');
  
        }else{

            setReceivedMaterial(data);
     
  
            
          
        }


  };


  const handleMetrics = (data) => {

    if(data === ''){

    setValueMetrics(JSON.stringify({}))

  }else{

    setValueMetrics(JSON.stringify(data));


      
    
  }



  }

  const handleNumpage = (number) => {


    setNumPage(number)

  }

  const getAllData = (incrementBy) => {


    const form_data = new FormData();

    form_data.append('NumPage', incrementBy)

    axiosInstance.post('/caosdb_alldata/', form_data,
                     {
                headers:{  
                  "Access-Control-Allow-Headers": "*",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Credentials": "true",
                  "Content-Type": "multipart/form-data",}, 

                 
              }
            ).then((response) => {

              console.log(response.data)
              setResult_Matrix(response.data['data_parse'])
              setGlobalMatrix(response.data['data_parse'])

              if( response.data['number_elem'] % 8 == 0){

                setTotal(Math.floor( response.data['number_elem']/ 8))

              }else {

                setTotal(Math.floor( response.data['number_elem']/ 8) + 1)

              }
              
                  
            })
            .catch((error) => {console.log(error)
              setResult_Matrix([])
              setGlobalMatrix([])
                              
          
          })




  }


 


   

    const handleSearch = () => {

      let SearchMatrix =[]

      Object.keys(ReceivedGeometryShape).forEach(key => {



        if(ReceivedGeometryShape[key]){

            SearchMatrix.push(key)

        }

      });



     

      const form_data = new FormData();
      form_data.append('SearchMatrix', SearchMatrix)
      form_data.append('SearchMaterail', ReceivedMaterial)
      form_data.append('SearchMetrics', ValueMetrics)
      

      console.log(form_data)
      
        axiosInstance.post('/caosdb_search/', form_data,
                       {
                  headers:{  
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": "true",
                    "Content-Type": "multipart/form-data",}, 

                   
                }
              ).then((response) => {

                console.log(response.data)
                setGlobalMatrix(response.data)
                setIsUploading(false)
                    
              })
              .catch((error) => {console.log(error)
                                
            
            })

       

    }


    const getAllMaterials_old = () =>{

              axiosInstance.post('/caosdb_allmaterial/', 
              {
        headers:{  
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          "Content-Type": "application/json",}, 

          
        }
        ).then((response) => {

        setListMaterial(response.data)
          
        })
        .catch((error) => {console.log(error)

          setListMaterial([])
                      

          })

              

    }

    const getAllMaterials  = () => {

      let domain = window.location.hostname;
      let api = '/api'
  
      let url_prod = 'https://' + domain + api
    
      let url_dev = 'http://' + domain +':5000' 
  


       // Initialize an EventSource to connect to the streaming endpoint
    const eventSource = new EventSource(`${url_dev}/caosdb_allmaterial`);

    // Handle incoming messages from the server
    eventSource.onmessage = (event) => {
      try {
        // Parse the incoming data (JSON string of material list)
        const newMaterials = JSON.parse(event.data);
        // Update the materials state with the latest data
        setListMaterial(newMaterials);
      } catch (error) {
        console.error('Error parsing message:', error);
      }
    };

    // Handle errors in the event source
    eventSource.onerror = (error) => {
      console.error('EventSource error:', error);
      eventSource.close(); // Close the connection on error
    };

    // Cleanup function to close the EventSource when the component unmounts
    return () => {
      eventSource.close();
    };
    }


    const handleThirdFilter = (data) => {

      if(data === ''){

        setGlobalMatrix(ResultMatrix)
      }else{



      console.log(data)

    const form_data = new FormData();
      form_data.append('SearchMatrix', JSON.stringify(data))
  
      
      
        axiosInstance.post('/caosdb_thirdfilter/', form_data,
                       {
                  headers:{  
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": "true",
                    "Content-Type": "multipart/form-data",}, 

                   
                }
              ).then((response) => {

                console.log(response.data)
                setGlobalMatrix(response.data)
                    
              })
              .catch((error) => {console.log(error)
                                
            
            })

      }

    }


  const handleSecondFilter = (data) => {

    console.log(data)

    const form_data = new FormData();
      form_data.append('SearchMatrix', JSON.stringify(data))
  
      
      
        axiosInstance.post('/caosdb_secondfilter/', form_data,
                       {
                  headers:{  
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": "true",
                    "Content-Type": "multipart/form-data",}, 

                   
                }
              ).then((response) => {

                console.log(response.data)
                setGlobalMatrix(response.data)
                    
              })
              .catch((error) => {console.log(error)
                                
            
            })


  } 


  useEffect(() => {
    setIsUploading(true)
    handleSearch()
  }, [ReceivedGeometryShape, ReceivedMaterial, ValueMetrics]);

 

    

  useEffect(() => { 


    setNumPage(1)

    getAllData(1)

    getAllMaterials()

    //setisAdmin(true)

  }, []);

   




    return(

        
            
                
<div className="container">
      <Navbar />
      <Sidebar handleGeometryShape ={handleGeometryShape}  handleMaterial = {handleMaterial} ListMaterial ={ListMaterial} handleMetrics = {handleMetrics}/>

      <Header data = {GlobalMatrix} handleNumpage = {handleNumpage}  getAllData = {getAllData} Totalelemt = {Total} handleSecondFilter = {handleSecondFilter} handleThirdFilter = {handleThirdFilter} />

      { isUploading? <UploadingBox WaitingMessage = {WaitingMessage}/> :null}
     
    </div>
             
            
          
    )




}