// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, html, #root {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  
  .container {
    display: grid;
    grid-template-rows: 60px 1fr; /* Navbar height and rest of the content */
    grid-template-columns: 200px 1fr; /* Sidebar width and main content */
    grid-template-areas:
      "navbar navbar"
      "sidebar main";
    height: 100vh;
  }
  
  
  .main {
    grid-area: main;
    padding: 20px;
    background-color: #fff;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/MainPage/MainPage.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,YAAY;EACd;;EAEA;IACE,aAAa;IACb,4BAA4B,EAAE,0CAA0C;IACxE,gCAAgC,EAAE,mCAAmC;IACrE;;oBAEgB;IAChB,aAAa;EACf;;;EAGA;IACE,eAAe;IACf,aAAa;IACb,sBAAsB;EACxB","sourcesContent":["body, html, #root {\n    margin: 0;\n    padding: 0;\n    height: 100%;\n  }\n  \n  .container {\n    display: grid;\n    grid-template-rows: 60px 1fr; /* Navbar height and rest of the content */\n    grid-template-columns: 200px 1fr; /* Sidebar width and main content */\n    grid-template-areas:\n      \"navbar navbar\"\n      \"sidebar main\";\n    height: 100vh;\n  }\n  \n  \n  .main {\n    grid-area: main;\n    padding: 20px;\n    background-color: #fff;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
