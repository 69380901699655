
import React, { useEffect, useState } from 'react';

import './Sidebar.css'

export default function GeometryShape ({ sendGeometryShape } ) {




       const [checkedItems, setCheckedItems] = useState({

        'Sphere' : false,
         'Ellipsoid': false,
         'Superellipsoid': false,
         'Spheroid': false,
         'Cylinder': false,
         'Cone': false,
        'Torus': false,
        'Cube': false,
        'Rectangular cuboid': false,
        'Helix': false,
        'Cut sphere': false,
        'Core-shell sphere' : false
       });

       const handleCheckboxChange = (event) => {
         const { name, checked } = event.target;
         setCheckedItems({
           ...checkedItems,
           [name]: checked,
         });

       };






  useEffect(() => {
    //console.log('Checked Items:', checkedItems);
    sendGeometryShape(checkedItems)
  }, [checkedItems]);


    return (

        <div>
       <h2 className='Title-Geometry'> Geometry Shape</h2>

       <hr className="separator" />


       <div className='Select-item'>
      {Object.keys(checkedItems).map((item) => (
        <div key={item} className="checkbox-item">
          <label>
            <input
              type="checkbox"
              name={item}
              checked={checkedItems[item] || false}
              onChange={handleCheckboxChange}
            />
            {item}
          </label>
        </div>
      ))}
      <div>
      </div>
    </div>





        </div>




    )

    


};