
import {createStore} from 'redux'
//const redux = require('redux') ; // defaut way to import javascript package


const loginRender = (state = {
    state_login : false,
    id_login : ' '} , action) => {

    if (action.type === 'login'){

            state = {
                state_login : state.state_login,
                id_login : state.id_login
            
            }
        }
    if (action.type === 'logout'){

        state =  {
            state_login : false,
            id_login : ' '}
        }
    return state;


};
export const LoginStore = createStore(loginRender);




