

import React, { useState } from 'react';
import './Header.css'
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { create, all } from 'mathjs'



const TmatrixEmbedding = ({ data }) => {

    


    
    const getKeywords = (currentElement) => {
        // Safely access the tmatrix_Keyword property
        const keywordString = currentElement?.tmatrix_Keyword;
    
        if (!keywordString) {
            console.log("Keyword data is undefined or not present.");
            return "No keywords"; // Provide a default fallback
        }
    
        try {
            // Replace single quotes with double quotes and parse the JSON
            const keywordsArray = JSON.parse(keywordString.replace(/'/g, '"'));
    
            // Check if the result is an array and then join it, or log error
            if (Array.isArray(keywordsArray)) {
                return keywordsArray.join(', ');
            } else {
                console.log("Parsed data is not an array:", keywordsArray);
                return "Invalid keyword format"; // fallback message
            }
        } catch (error) {
            console.error("Parsing error:", error);
            return "Error parsing keywords"; // error handling
        }
    };

   

    const info = {
        Shape: data.geometry_shape,
        Material: data.material_name,
        Description: data.tmatrix_description,
        
        Keyword: getKeywords(data)
    };








return(


<div className="info-container">
            <h2>{info.title}</h2>
            <p><strong>Shape:</strong> {info.Shape}</p>
            <p><strong>Material:</strong> {info.Material}</p>
            <p><strong>Description:</strong> {info.Description}</p>
            <p><strong>Keyword:</strong> {info.Keyword}</p>
        </div>
)


};

export default TmatrixEmbedding ;