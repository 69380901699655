import React, { useEffect } from "react";
import './Error.css'




export default function ErrorPage() {



    return(
        <div className="Error">
          
          <h4>Opps!</h4>
          <h3>We can not find the page you are looking for </h3>
            
          
         
        </div>
      
)
}

