// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.SideBar {

  grid-area: sidebar;
  background-color: #f4f4f4;
  padding: 20px;
}


.body {

margin: 0;
padding: 0;
}


.Title-Geometry{

    font-size: 17px;
  font-weight: normal;
  margin-bottom: 10px;

  
}

.Select-item {
  text-align: left;
  padding: 1px;
}

label {
  font-size: 15px;
  font-weight: normal;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  font-size: 18px;
  color: #333;
}



.checkbox-item {
  margin-bottom: 10px;
}


.separator {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #ccc;
}

.input-menu {
  width: 150px;
  padding: 8px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}


datalist {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-field-side {
  flex-grow: 0; /* Adjust flex-grow to 0 if you don't want it to grow */
  width: 140px; /* Set a specific width */
  margin: 0 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}




`, "",{"version":3,"sources":["webpack://./src/Components/Sidebar/Sidebar.css"],"names":[],"mappings":";;AAEA;;EAEE,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;AACf;;;AAGA;;AAEA,SAAS;AACT,UAAU;AACV;;;AAGA;;IAEI,eAAe;EACjB,mBAAmB;EACnB,mBAAmB;;;AAGrB;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;;;AAIA;EACE,mBAAmB;AACrB;;;AAGA;EACE,cAAc;EACd,SAAS;EACT,0BAA0B;AAC5B;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,WAAW;EACX,sBAAsB;EACtB,kBAAkB;AACpB;;;AAGA;EACE,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,YAAY,EAAE,uDAAuD;EACrE,YAAY,EAAE,yBAAyB;EACvC,cAAc;EACd,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;AACpB","sourcesContent":["\n\n.SideBar {\n\n  grid-area: sidebar;\n  background-color: #f4f4f4;\n  padding: 20px;\n}\n\n\n.body {\n\nmargin: 0;\npadding: 0;\n}\n\n\n.Title-Geometry{\n\n    font-size: 17px;\n  font-weight: normal;\n  margin-bottom: 10px;\n\n  \n}\n\n.Select-item {\n  text-align: left;\n  padding: 1px;\n}\n\nlabel {\n  font-size: 15px;\n  font-weight: normal;\n}\n\nul {\n  list-style: none;\n  padding: 0;\n}\n\nli {\n  font-size: 18px;\n  color: #333;\n}\n\n\n\n.checkbox-item {\n  margin-bottom: 10px;\n}\n\n\n.separator {\n  margin: 20px 0;\n  border: 0;\n  border-top: 1px solid #ccc;\n}\n\n.input-menu {\n  width: 150px;\n  padding: 8px;\n  margin: 5px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n}\n\n\ndatalist {\n  border: 1px solid #ccc;\n  border-radius: 4px;\n}\n\n.input-field-side {\n  flex-grow: 0; /* Adjust flex-grow to 0 if you don't want it to grow */\n  width: 140px; /* Set a specific width */\n  margin: 0 10px;\n  padding: 8px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
