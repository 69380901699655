// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Error{
    /*background-image: url('../../../public/Background.jpg');*/
    display: inline-block;
    background: #FFFFFF;
    width: 434px;
    height: 550px;
    margin-top: 10%;
    border-radius: 22px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}`, "",{"version":3,"sources":["webpack://./src/Components/ErrorPage/Error.css"],"names":[],"mappings":"AAAA;IACI,2DAA2D;IAC3D,qBAAqB;IACrB,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,4EAA4E;AAChF","sourcesContent":[".Error{\n    /*background-image: url('../../../public/Background.jpg');*/\n    display: inline-block;\n    background: #FFFFFF;\n    width: 434px;\n    height: 550px;\n    margin-top: 10%;\n    border-radius: 22px;\n    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
