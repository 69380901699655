import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import LogIn from "./Components/LogIn/LogIn.js";
import MainPage from "./Components/MainPage/MainPage.js";
import ErrorPage from "./Components/ErrorPage/Error.js";
import './App.css';

function App() {
  const [isLogin, setIsLogin] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    // Read query parameter to determine authentication status
    const urlParams = new URLSearchParams(window.location.search);
    const isLoginParam = urlParams.get('isLogin');
    const userIdParam = urlParams.get('userId');
    setIsLogin(!!isLoginParam); // Convert to boolean
    setUserId(userIdParam)
    
  }, []);


  const PrivateRoute = ({ children }) => {
    
    localStorage.setItem('user_id', userId);
    return isLogin ? children : <Navigate to='/' push={true} />;
  };


  

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={isLogin ? <Navigate push={true} to='/Home' /> : <LogIn />} />
          <Route path='/Home' element={<PrivateRoute><MainPage /></PrivateRoute>} />
          <Route path='*' element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
