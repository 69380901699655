
import React, { useEffect, useState } from 'react';

import './Sidebar.css'

export default function MetricsElemts ({handleMetrics}) {



  const [selectedElement, setSelectedElement] = useState('');
  const [inputValue, setInputValue] = useState('');



  const elements = ['Frequency', 'Vacuum Wavelength'];

  const handleSelectChange = (event) => {

   
    if (event.target.value === 'clear') {
      setSelectedElement('');
      setInputValue('');  // Reset input value when selection changes
    } else {
      setSelectedElement(event.target.value);
      setInputValue('');  // Reset input value when selection changes
    }
    
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    handleMetrics(JSON.stringify({selectedElement : event.target.value}))
  };

  



    


    return (


      <div>

<h2 className='Title-Geometry'>Metrics</h2>

<hr className="separator" />

      <select id="element-select" value={selectedElement} onChange={handleSelectChange} className="input-menu" >
        <option value="" disabled>Select Metrics</option>
        {elements && <option value="clear">Clear selection</option>}
        {elements.map((element) => (
          <option key={element} value={element}>
            {element}
          </option>
        ))}
      </select>

      {selectedElement && (
        <div>
          <label htmlFor="element-value">{`Enter ${selectedElement} Value:`}</label>
          <input
            type="text"
            id="element-value"
            value={inputValue}
            onChange={handleInputChange}
            className="input-menu"
          />
        </div>
      )}


  


    </div>

 

 


    
      
    

      




    )

    


};