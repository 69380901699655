import React, { useState } from 'react';

const Sidebar = ({  items, onItemSelected }) => {

  return (
    <div style={{ width: '250px', height: '70vh', background: '#f0f0f0', padding: '20px' }}>
      <ul style={{ listStyle: 'none', padding: 0 }}>
        {items.map((item) => (
          <li
            key={item.id}
            style={{
              padding: '10px',
              backgroundColor: item.selected ? '#ccc' : 'transparent',
              cursor: 'pointer',
            }}
            onClick={() => onItemSelected(item)}
          >
            {item.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar ;